@import '../../../styles/common';

.RestorePassword {
  &__footer {
    display: flex;
    justify-content: flex-end;
  }
  &__success {
    width: 100%;
    text-align: center;
    color: @platinum_color;
  }
}
