@import '../../../../styles/variables';

.DropdownListItem {
  align-items: center;
  display: flex;
  color: #3c4858;
  cursor: pointer;
  height: 40px;
  padding: 6px 19px;

  &:hover {
    background-color: @snow_color;
  }

  &_selected {
    background-color: @snow_color;
  }
  &_disabled {
    cursor: default;
    background-color: #fff !important;
    color: #ccc !important;
  }
}
