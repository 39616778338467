.ShopifyConnectForm {
  &__name {
    display: flex;
  }
  &__domain {
    line-height: 40px;
    margin-left: 12px;
    font-weight: bold;
  }
}
