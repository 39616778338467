.DropdownNativeList {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;

  &_cover {
    width: 100%;
    height: 100%;
  }
}
