@import '../../../styles/variables';

.Checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
    margin-right: 12px;
  }
  label {
    transform: none;
    color: @platinum_color;
    cursor: pointer;
  }

  &:hover {
    .Check__mark {
      stroke: #0072ff;
    }
  }
}
