:root {
  --PhoneInputCountrySelect-marginRight: 12px !important;
}

.PhoneInputCountry {
  &SelectArrow {
    display: none !important;
  }
}

.PhoneInputInput {
  &.Input_active {
    label.form-label {
      transform: scale(0.9) translateY(0) translateX(-28px);
    }
  }
}
