@import '../../Input/Input';

.Dropdown {
  position: relative;

  &_opened {
    input {
      .input-focus();
    }
  }
}
