@import '../../../styles/variables';

.RequestDemo {
  &__text {
    text-align: center;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__success {
    width: 100%;
    text-align: center;
  }
}
