.IntegrationPairingLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  &__pair {
    margin: 0 25px;
  }
}
