.DropdownInput {
  position: relative;

  input[readonly] {
    cursor: pointer;
  }
  svg {
    position: absolute;
    right: 10px;
    bottom: 18px;
  }
}
