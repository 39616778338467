.WaitingButton {
  position: relative;
  transition: padding-right 0.3s !important;
  transition-timing-function: ease-in !important;

  &_active {
    padding-right: 50px !important;
  }

  &__spinner {
    position: absolute;
    top: 50%;
    left: auto;
    right: 18px;
    margin-top: -8px;
  }
}
