@import 'variables.less';

html {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  font-family: 'Lato', 'Helvetica Neue', Arial, sans-serif;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  width: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
}

.AuthPage {
  max-width: 456px;
  min-width: auto !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('../../public/images/auth.svg') no-repeat center center;
  background-size: contain;
  margin: 0 auto;

  &__mobileWarning {
    display: none;
  }

  form {
    margin-top: 40px;
  }
  h1 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
  }
  p {
    color: @smoke_color;
    text-align: center;
    font-size: 14px;
  }
  b,
  &__mark {
    color: @brand_color;
  }

  &__content {
    padding: 0 10px;
    width: 100%;
    max-width: 404px;
    margin: auto;
  }
  &__footer {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: @smoke_color;
    font-size: 13px;
    font-weight: bold;
  }
}

.AuthPageLogo {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}
