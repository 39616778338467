@import '../../../styles/variables';

.SidebarElement {
  padding: 0 0 16px;
  text-align: initial;

  &:first-child {
    margin-top: 0;
  }

  &__header {
    font-weight: 600;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    line-height: 40px;
  }
  &__title {
    color: @smoke_color;
    line-height: 40px;
    font-weight: 600;
    font-size: 13px;
  }
  &__separator {
    height: 2px;
    margin: 8px 0;
    background: @dirty_jon_snow_color;

    /** Если перед разделителем никого нет - не надо его показывать */
    &:first-child {
      display: none;
    }
  }

  .form-group:last-child {
    margin-bottom: 0;
  }
}
