.ErrorBoundary {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;

  &__content {
    width: 500px;
  }
}
