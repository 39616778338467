@import '../../../../styles/variables';

.DropdownList {
  background-color: #fff;
  border: 1px solid @dirty_jon_snow_color;
  margin-top: 8px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  text-align: left;
  border-radius: 4px;
  padding: 3px 0;
  display: none;

  &_opened {
    display: block;
  }
}
