@import '../../../styles/variables.less';

.SignUp {
  &__promoCode {
    font-size: 15px;
    font-weight: bold;
    color: @emerald;
    margin: 16px 0;
    text-align: center;
  }
  &__success {
    width: 100%;
    text-align: center;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
