@import '../../../../styles/variables';

.ShopifySidebarElement {
  &__header {
    display: flex;
    align-items: center;
  }
  &__name {
    margin-left: 8px;
  }
}

.ShopifyCart {
  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__price {
    align-self: flex-end;
    flex-shrink: 0;

    span {
      color: @smoke_color;
    }
  }
  &__total {
    margin-top: 24px;
    text-align: right;
  }
}
