@import './variables';

.Check {
  &__plate {
    fill: #fff;
    stroke: @brand_color;
  }
  &__mark {
    stroke: #fff;
  }

  &_checked &__plate {
    fill: @brand_color;
  }
  &_checked &__mark {
    stroke: #fff !important;
  }

  &_disabled &__plate {
    stroke: @carbon_color;
  }
  &_disabled&_checked &__plate {
    fill: @carbon_color;
  }
  &_disabled &__mark {
    stroke: #fff !important;
  }

  &:hover &__mark {
    stroke: @brand_color;
  }
}

.Spinner {
  display: block;

  &__inner {
    transform-origin: 50%;
    animation: spinner-animation 1s infinite linear;
  }
}
@keyframes spinner-animation {
  0% {
    animation-timing-function: cubic-bezier(0.5856, 0.0703, 0.4143, 0.9297);
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
