@import '../../../styles/variables';

.input-state(@color) {
  border-color: @color;
  outline: none;
  box-shadow: 0 1px 0 0 @color;
}
.input-focus() {
  .input-state(@brand_color);
}
.input-invalid() {
  .input-state(@fire_rose_color);
}

input.form-control,
textarea.form-control {
  position: relative;
  border: none;
  border-bottom: 1px solid @dirty_jon_snow_color;
  border-radius: 0;
  padding: 0;
  background-color: transparent !important;
  transition: none;
  color: @platinum_color;

  &:focus {
    .input-focus();
  }

  &.is-invalid {
    border-color: @fire_rose_color;
    background-image: none;
    padding: inherit;

    &:focus {
      .input-invalid();
    }
  }

  &:disabled {
    color: @smoke_color;
  }
}
textarea.form-control {
  padding: 7px 0;
}

input.form-control:-webkit-autofill,
input.form-control:-webkit-autofill:hover,
input.form-control:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input.form-control:-webkit-autofill {
  &:focus {
    box-shadow: 0 1px 0 0 @brand_color, 0 0 0 30px white inset !important;
  }
  &.is-invalid:focus {
    box-shadow: 0 1px 0 0 @fire_rose_color, 0 0 0 30px white inset !important;
  }
}
input.form-control:-webkit-autofill {
  -webkit-text-fill-color: @platinum_color !important;
}

.invalid-feedback {
  color: @fire_rose_color;
}

.form-text {
  color: @smoke_color;

  p {
    margin-bottom: 0.25rem;
  }
}

.Input {
  text-align: initial;

  label.form-label {
    pointer-events: none;
    transform: translateY(28px);
    transition: all 0.15s cubic-bezier(0.35, 0, 0.25, 1);
    z-index: 1;
    cursor: text;
  }

  &_active {
    label.form-label {
      transform: scale(0.9) translateY(0);
    }
  }
}
